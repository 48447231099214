import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Form from "../components/form"

const StyledPantaTeikningar = styled.div`
  position: static;
`

const PantaTeikningar = () => {
  return (
    <Layout>
      <Helmet>
        <title>Agnes Suto | Panta Teikningar</title>
      </Helmet>
      <StyledPantaTeikningar>
        <Form />
      </StyledPantaTeikningar>
    </Layout>
  )
}

export default PantaTeikningar
