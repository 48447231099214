import React, { Component } from "react"
import styled from "styled-components"
import { colors } from "./siteVars"

const StyledTextareaContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  label {
    position: absolute;
    top: 0;
    z-index: 10;
    margin-left: 1rem;
    margin-bottom: 1rem;
    transition: all 200ms ease-in-out;
    background: #000;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    opacity: 0;
    pointer-events: none;
    &:after {
      position: absolute;
      bottom: -0.5rem;
      left: 35%;
      background: #000;
      content: "";
      height: 0.6rem;
      width: 1rem;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }
  textarea {
    font-family: inherit;
    transition: all 200ms ease-in-out;
    margin-bottom: 1rem;
    font-size: 1rem;
    border: 1px solid ${colors.mediumGrey};
    resize: none;
    padding: 1rem;
    resize: none;
    &:focus {
      outline: none;
      border-color: #000;
      + label {
        opacity: 1;
        top: -2.5rem;
      }
    }
  }
`

export default class Textarea extends Component {
  render() {
    const { props } = this
    return (
      <StyledTextareaContainer name={props.name}>
        <textarea
          type={props.type}
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          id={props.id}
          required={props.required}
          rows={props.rows}
          cols={props.cols}
          className={props.className}
        />
        <label htmlFor={props.name}>{props.label}</label>
      </StyledTextareaContainer>
    )
  }
}
