import React, { useState } from "react"
import styled from "styled-components"
import Input from "./input"
import Button from "./button"
import Textarea from "./textarea"
import { useStaticQuery, graphql } from "gatsby"
import Dropzone from "./dropzone"
import Axios from "axios"

const StyledForm = styled.div`
  padding: 1rem;
  max-width: 1000px;
  margin: auto;
  textarea {
  }
  .form-header {
    max-width: 600px;
    text-align: center;
    margin: auto;
    h1 {
      margin-bottom: 0;
    }
    margin-bottom: 2rem;
  }
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    .dropzone {
      grid-column: 1 / 3;
    }
  }
  .form-button-section {
    grid-column: 1 / 3;
    display: flex;
    justify-content: center;
    button {
      margin-top: 2rem;
      display: block;
      width: 50%;
    }
  }
  @media (max-width: 768px) {
    form {
      display: block;
    }
    .form-button-section {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      button {
        width: 100%;
      }
    }
  }
`

const Form = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [dropzoneImg, setDropzoneImg] = useState(null)
  const [file, setFile] = useState({})
  const [fileName, setFileName] = useState("")

  const query = useStaticQuery(graphql`
    query {
      contentfulGeneral {
        teikningarOrdersActive
        ordersDisabledText {
          childMarkdownRemark {
            html
          }
        }
        pantaTeikningarText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  const handleSubmit = e => {}

  const selectFile = () => {
    const btn = document.querySelector("#selectButton")
    btn.click()
  }

  const readUpload = e => {
    const img = document.querySelector("#hiddenImg")
    const file = e.target.files[0]
    const reader = new FileReader()

    console.log(file)
    reader.onload = event => {
      if (event.target.result && file.type.split("/")[0] === "image") {
        setDropzoneImg(event.target.result)
        img.src = event.target.result
      }
    }
    setFile(file)
    setFileName(file.name ? file.name : "")

    reader.readAsDataURL(file)
  }

  const triggerSubmit = () => {
    const loader = document.querySelector("#layoutLoader")
    loader.classList.add("active")
    const urlInput = document.querySelector("#urlInput")
    const img = document.querySelector("#hiddenImg")
    Axios.post("https://api.cloudinary.com/v1_1/katalysatorav/image/upload", {
      file: img.src,
      upload_preset: "tgbwaose",
    })
      .then(res => {
        console.log(res.data)
        urlInput.value = res.data.url
        const button = document.querySelector("#formSubmit")
        button.click()
        loader.classList.remove("active")
      })
      .catch(err => {
        loader.classList.remove("active")
        console.log(err.response)
      })
  }

  return (
    <StyledForm
      className={
        query.contentfulGeneral.teikningarOrdersActive ? "" : "disabled"
      }
    >
      <div
        className="form-header"
        dangerouslySetInnerHTML={{
          __html: query.contentfulGeneral.teikningarOrdersActive
            ? query.contentfulGeneral.pantaTeikningarText.childMarkdownRemark
                .html
            : query.contentfulGeneral.ordersDisabledText.childMarkdownRemark
                .html,
        }}
      ></div>
      <div>
        {query.contentfulGeneral.teikningarOrdersActive ? (
          <>
            <img src="" alt="" id="hiddenImg" style={{ display: "none" }} />

            <form
              method="post"
              action="/takk"
              onSubmit={handleSubmit}
              netlify-honeypot="bot-field"
              data-netlify="true"
              name={"Teikningar Order"}
            >
              <input type="hidden" name="bot-field" />
              <input
                type="hidden"
                name="form-name"
                value={"Teikningar Order"}
              />
              <div>
                <Input
                  type="text"
                  name="Fornafn"
                  label="Fornafn"
                  placeholder="Fornafn"
                  value={firstName}
                  required
                  onChange={e => {
                    setFirstName(e.target.value)
                  }}
                />
                <Input
                  type="text"
                  name="Eftirnafn"
                  required
                  value={lastName}
                  label="Eftirnafn"
                  placeholder="Eftirnafn"
                  onChange={e => {
                    setLastName(e.target.value)
                  }}
                />

                <Input
                  required
                  placeholder="Netfang"
                  label="Netfang"
                  type="email"
                  name="Netfang"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                />
                <Input
                  required
                  placeholder="Sími"
                  type="tel"
                  name="Sími"
                  value={phone}
                  label="Sími"
                  onChange={e => {
                    setPhone(e.target.value)
                  }}
                />
              </div>
              <div>
                <input hidden type="url" name="image" id="urlInput" />
                <Textarea
                  placeholder="Skílaboð"
                  name="Skílaboð"
                  label="Skílaboð"
                  value={message}
                  rows="12"
                  onChange={e => {
                    setMessage(e.target.value)
                  }}
                />
              </div>
              <div className="dropzone">
                <Dropzone
                  bg={dropzoneImg}
                  status={fileName.length > 0 ? fileName : "No file selected"}
                  onClick={selectFile}
                />
              </div>
              <input type="submit" hidden id="formSubmit" />
            </form>
            <input
              type="file"
              name="Mynd"
              hidden
              accept="image/png, image/jpeg"
              id="selectButton"
              onChange={e => {
                readUpload(e)
              }}
            />
            <div className="form-button-section">
              <Button type="button" onClick={triggerSubmit}>
                SENDA
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </StyledForm>
  )
}

export default Form
