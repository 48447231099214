import React from "react"
import styled from "styled-components"
import { colors } from "../components/siteVars"

const StyledDropzone = styled.div`
  width: 100%;
  height: 200px;
  ${props =>
    props.bg
      ? "background: url(" + props.bg + ");"
      : "background: " + colors.lightGray};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  border: 1px dashed ${colors.mediumGray};
  cursor: pointer;
  .drop-inner {
    background: ${colors.lightGray};
    padding: 1rem 2rem;
    border-radius: 1rem;
  }
`

const Dropzone = props => {
  return (
    <StyledDropzone id="dropContainer" onClick={props.onClick} bg={props.bg}>
      <div className="drop-container">
        <div className="drop-inner">
          <div className="drop-img">{props.bg ? null : <h3>Mynd</h3>}</div>
          <div className="drop-status">
            <p>{props.status}</p>
          </div>
        </div>
      </div>
    </StyledDropzone>
  )
}

export default Dropzone
